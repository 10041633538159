@charset "UTF-8";
/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Custom Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Theme Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    CTA buttons
----------------------------------------------------------------------------- */
.text-theme-primary {
  color: #004dc1 !important;
}

.text-theme-secondary {
  color: #243f86 !important;
}

.border-theme-primary {
  border-color: #004dc1 !important;
}

.border-theme-secondary {
  border-color: #243f86 !important;
}

.text-red {
  color: #ec0000 !important;
}

.text-light-grey {
  color: #ccc !important;
}
.text-light-grey:after {
  background-color: #ccc !important;
}

/* ----------------------------------------------------------------------------
    Font Icons
----------------------------------------------------------------------------- */
@font-face {
  font-family: "icons";
  src: url("../fonts/icons.eot?21099076");
  src: url("../fonts/icons.eot?21099076#iefix") format("embedded-opentype"), url("../fonts/icons.woff2?21099076") format("woff2"), url("../fonts/icons.woff?21099076") format("woff"), url("../fonts/icons.ttf?21099076") format("truetype"), url("../fonts/icons.svg?21099076#icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
[class^=icon-]:before,
[class*=" icon-"]:before {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-dribbble:before {
  content: "\e801";
}

.icon-instagram:before {
  content: "\e802";
}

.icon-behance:before {
  content: "\e803";
}

.icon-linkedin:before {
  content: "\e804";
}

.icon-facebook:before {
  content: "\e805";
}

.icon-twitter:before {
  content: "\e806";
}

.icon-gplus:before {
  content: "\e807";
}

.icon-pinterest:before {
  content: "\e808";
}

.icon-tumblr:before {
  content: "\e809";
}

.icon-down:before {
  content: "\e810";
}

.icon-left:before {
  content: "\e811";
}

.icon-right:before {
  content: "\e812";
}

.icon-up:before {
  content: "\e813";
}

.icon-left-small:before {
  content: "\e814";
}

.icon-right-small:before {
  content: "\e815";
}

.icon-up-small:before {
  content: "\e816";
}

.icon-down-small:before {
  content: "\e817";
}

.icon-down-thin:before {
  content: "\e818";
}

.icon-left-thin:before {
  content: "\e819";
}

.icon-right-thin:before {
  content: "\e820";
}

.icon-up-thin:before {
  content: "\e821";
}

.icon-down-arrow:before {
  content: "\e822";
}

.icon-left-arrow:before {
  content: "\e823";
}

.icon-right-arrow:before {
  content: "\e824";
}

.icon-up-arrow:before {
  content: "\e825";
}

.icon-menu:before {
  content: "\e826";
}

.icon-home:before {
  content: "\e827";
}

.icon-lock:before {
  content: "\e828";
}

.icon-phone:before {
  content: "\e829";
}

.icon-chat:before {
  content: "\e830";
}

.icon-mail:before {
  content: "\e831";
}

.icon-comment:before {
  content: "\e832";
}

.icon-at:before {
  content: "\e833";
}

.icon-star:before {
  content: "\e834";
}

.icon-search:before {
  content: "\e835";
}

.icon-zoom:before {
  content: "\e836";
}

.icon-cog:before {
  content: "\e837";
}

.icon-link:before {
  content: "\e838";
}

.icon-share:before {
  content: "\e839";
}

.icon-check:before {
  content: "\e840";
}

.icon-cancel:before {
  content: "\e841";
}

.icon-plus:before {
  content: "\e842";
}

.icon-minus:before {
  content: "\e843";
}

.icon-close:before {
  content: "\e844";
}

.icon-user:before {
  content: "\e845";
}

.icon-spinner:before {
  content: "\e846";
}

.icon-youtube:before {
  content: "\e847";
}

a.social {
  display: inline-block;
  height: 40px;
  width: 40px;
  margin: 4px;
  color: #fff !important;
  background-color: #ccc;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 18px;
  text-align: center;
  overflow: hidden;
  vertical-align: middle;
  border: 0 !important;
  border-radius: 30px;
  transition: all 0.15s linear 0s;
}

a.social [class^=icon-]:before {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 42px;
}

a.social:hover,
a.social:active {
  font-size: 20px;
  border-radius: 4px;
  /*transform: rotate(360deg);*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a.social.dribbble:hover,
a.social.dribbble:active {
  background-color: #ea4c89;
}

a.social.instagram:hover,
a.social.instagram:active {
  background-color: #49789d;
}

a.social.behance:hover,
a.social.behance:active {
  background-color: #1769ff;
}

a.social.facebook:hover,
a.social.facebook:active {
  background-color: #3f5495;
}

a.social.twitter:hover,
a.social.twitter:active {
  background-color: #5d87c4;
}

a.social.linkedin:hover,
a.social.linkedin:active {
  background-color: #2575b2;
}

a.social.gplus:hover,
a.social.gplus:active {
  background-color: #cc4436;
}

a.social.pinterest:hover,
a.social.pinterest:active {
  background-color: #c2142f;
}

a.social.tumblr:hover,
a.social.tumblr:active {
  background-color: #529ecc;
}

a.social.youtube:hover,
a.social.youtube:active {
  background-color: #ff0000;
}

/* open-sans-300 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-300.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-300.woff") format("woff"), url("../fonts/open-sans-v18-latin-300.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-300.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-regular.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-regular.woff") format("woff"), url("../fonts/open-sans-v18-latin-regular.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-regular.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-600.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-600.woff") format("woff"), url("../fonts/open-sans-v18-latin-600.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-600.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-600italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-600italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-600italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-600italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-600italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-600italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-600italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-700.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-700.woff") format("woff"), url("../fonts/open-sans-v18-latin-700.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-700.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-700italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-700italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-700italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-700italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-700italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* fjalla-one-regular - latin */
@font-face {
  font-family: "Fjalla One";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/fjalla-one-v8-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/fjalla-one-v8-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/fjalla-one-v8-latin-regular.woff2") format("woff2"), url("../fonts/fjalla-one-v8-latin-regular.woff") format("woff"), url("../fonts/fjalla-one-v8-latin-regular.ttf") format("truetype"), url("../fonts/fjalla-one-v8-latin-regular.svg#FjallaOne") format("svg"); /* Legacy iOS */
}
/* roboto-slab-regular - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-regular.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-regular.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-regular.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-regular.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* roboto-slab-600 - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-600.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-600.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-600.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-600.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* roboto-slab-700 - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-700.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-700.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-700.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-700.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* montserrat-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/montserrat-v25-latin-300.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-300.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/montserrat-v25-latin-300italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-300italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/montserrat-v25-latin-regular.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-regular.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/montserrat-v25-latin-italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/montserrat-v25-latin-500.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-500.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/montserrat-v25-latin-500italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-500italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/montserrat-v25-latin-600.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-600.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/montserrat-v25-latin-600italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-600italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/montserrat-v25-latin-700.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-700.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/montserrat-v25-latin-700italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-700italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/montserrat-v25-latin-800.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-800.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* material icons */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIcons-Regular.woff2") format("woff2");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale;
}

/* fallback */
@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIconsOutlined-Regular.otf") format("woff2");
}
.material-icons-outlined {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale;
}

/* fallback */
@font-face {
  font-family: "Material Icons Round";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIconsRound-Regular.otf") format("woff2");
}
.material-icons-round {
  font-family: "Material Icons Round";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale;
}

/* ----------------------------------------------------------------------------
    CSS Variable, needs to be set in :root
----------------------------------------------------------------------------- */
:root {
  --font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* ----------------------------------------------------------------------------
    Smooth Scrolling
----------------------------------------------------------------------------- */
@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}
[id] {
  scroll-margin-top: 1rem;
}

/* ----------------------------------------------------------------------------
    Typography
----------------------------------------------------------------------------- */
body {
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 600;
  font-family: var(--font-family);
  line-height: 1.4;
  color: #06c;
}
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.h1 a:hover,
.h2 a:hover,
.h3 a:hover,
.h4 a:hover,
.h5 a:hover,
.h6 a:hover {
  color: inherit;
}

h1,
.h1 {
  font-size: 1.602em;
}

h2,
.h2 {
  font-size: 1.424em;
}

h3,
.h3 {
  font-size: 1.266em;
}

h4,
.h4 {
  font-size: 1.125em;
}

h5,
.h5 {
  font-size: 1em;
}

@media (min-width: 62em) {
  /* Large devices (desktops, 992px and up) */
  h1,
  .h1 {
    font-size: 2.074em;
  }
  h2,
  .h2 {
    font-size: 1.728em;
  }
  h3,
  .h3 {
    font-size: 1.44em;
  }
  h4,
  .h4 {
    font-size: 1.2em;
  }
  h5,
  .h5 {
    font-size: 1.1em;
  }
}
@media (min-width: 75em) {
  /* Extra large devices (large desktops, 1200px and up) */
  h1,
  .h1 {
    font-size: 2.441em;
  }
  h2,
  .h2 {
    font-size: 1.953em;
  }
  h3,
  .h3 {
    font-size: 1.563em;
  }
  h4,
  .h4 {
    font-size: 1.25em;
  }
  h5,
  .h5 {
    font-size: 1.1em;
  }
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

a {
  color: #316ce8;
  text-decoration: none;
  outline: none !important;
}

a:not(.btn, .btn-cta, .cta-btn, .social):hover,
a:not(.btn, .btn-cta, .cta-btn, .social):active {
  text-decoration: underline 1px dotted currentColor;
  text-underline-offset: 0.3em;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #1752cf;
}

a.img:hover,
a.img:active {
  text-decoration: none;
  border: 0;
}

a[href^="tel:"] {
  color: inherit;
  border: 0;
}

ul.nav,
ol.nav {
  margin-left: 0;
  margin-top: 1em;
  margin-bottom: 1em;
}

.cfix:after,
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

#content,
#main_content,
.container,
.container-fluid,
.break_word {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

/* ----------------------------------------------------------------------------
    Forms Elements
----------------------------------------------------------------------------- */
.opacity-1 {
  opacity: 1 !important;
}

.display-none {
  display: none !important;
}

/* ----------------------------------------------------------------------------
    Buttons
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Tables
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Modals
----------------------------------------------------------------------------- */
.modal-header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.modal-header .close {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  margin-top: -0.7rem;
}

.modal-body .btn-close {
  opacity: 1;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  text-align: center;
  z-index: 100;
  background-color: #ffffff;
  border-bottom-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

/* ----------------------------------------------------------------------------
  Dropdown List
----------------------------------------------------------------------------- */
.dropdown-list {
  margin: auto;
  width: auto;
  position: relative;
  box-sizing: border-box;
}

.dropdown-list ul.drop {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dropdown-list li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dropdown-list span.dropdown {
  display: block;
  margin: 0;
  padding: 0 10px;
  width: auto;
  height: 40px;
  line-height: 38px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #a1c4e5;
  cursor: pointer;
  overflow: hidden;
}

.dropdown-list span.dropdown.is-active {
  border-radius: 3px 3px 0 0;
}

.dropdown-list span.dropdown:hover,
.dropdown-list span.dropdown:active {
  background: #f1f1f1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.dropdown-list span.dropdown:after {
  float: right;
  margin-top: 0;
  margin-left: 10px;
  width: 15px;
  height: 100%;
  content: "\e817";
  color: ineherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}

.dropdown-list span.dropdown.is-active:after {
  content: "\e816";
}

.dropdown-list a:hover {
  text-decoration: none;
  border-bottom: 0;
}

.dropdown-list .drop {
  display: block;
  float: left;
  margin: 0;
  width: 100%;
  max-height: 0;
  position: absolute;
  background: #fff;
  top: 40px;
  border-radius: 0 0 3px 3px;
  z-index: 10;
  overflow-y: auto;
  opacity: 0;
  transition: max-height 0.3s cubic-bezier(0, 0.6, 0, 1), opacity 0.3s cubic-bezier(0, 0.6, 0, 1);
}

.dropdown-list span.is-active + .drop {
  max-height: 350px;
  border: 1px solid #a1c4e5;
  border-top: 0;
  opacity: 1;
  transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.dropdown-list .drop li {
  float: none;
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  height: auto;
  border-top: 1px dotted #ccc;
}

.dropdown-list .drop li:first-child {
  border-top: 0;
}

.dropdown-list .drop li a {
  float: none;
  display: block;
  margin: 0;
  padding: 6px 10px 6px 25px;
  height: auto;
  width: auto;
  text-transform: none;
  color: inherit;
  background: #fff;
  text-align: left;
  border-radius: 0;
  border: 0;
}

.dropdown-list .drop li a:hover,
.dropdown-list .drop li a:active {
  background: #f1f1f1;
}

.dropdown-list .drop li a:before {
  display: inline-block;
  margin-left: -15px;
  margin-right: 5px;
  width: 10px;
  height: inherit;
  content: "»";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}

#footer-cta .dropdown-list.drop-up span.dropdown:after {
  content: "\e816";
}
#footer-cta .dropdown-list.drop-up span.dropdown.is-active:after {
  content: "\e817";
}
#footer-cta .dropdown-list.drop-up.dropdown-cta span.dropdown + .drop {
  top: auto;
  bottom: 45px;
}
#footer-cta .dropdown-list.drop-up .drop {
  bottom: 51px;
  top: auto;
  border-top: 1px solid #a1c4e5 !important;
  border-radius: 3px 3px 0 0;
}

ul,
ol {
  padding-left: 30px;
}

ul li,
ol li {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

ul.check,
ul.star,
ul.carrot {
  margin-left: 0;
  padding-left: 0;
  list-style: none outside;
  line-height: inherit;
}

ul.check li,
ul.star li,
ul.carrot li {
  padding-left: 1.3em;
  margin-left: 0;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  position: relative;
}

ul.check li li,
ul.star li li,
ul.carrot li li {
  padding-left: 0;
}

ul.carrot li {
  padding-left: 0.9em;
}

ul.check > li:before,
ul.star > li:before,
ul.carrot > li:before {
  display: block;
  margin-top: 0.1em;
  width: 1em;
  height: inherit;
  color: inherit;
  font-size: 0.9em;
  font-weight: normal !important;
  word-wrap: normal;
  position: absolute;
  left: 0;
}

ul.check > li:before {
  content: "\e840";
  font-family: "icons";
}

ul.star > li:before {
  content: "\e834";
  font-family: "icons";
}

ul.carrot > li:before {
  content: "»";
  font-family: "icons";
  margin-top: 0.2em;
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
  width: 1em;
}

/* ----------------------------------------------------------------------------
  Accordion
----------------------------------------------------------------------------- */
.accordion-wrap {
  margin-bottom: 1rem;
}
.accordion-wrap .accordion {
  padding: 0;
  border-bottom: 1px dotted #ccc;
  overflow: hidden;
}
.accordion-wrap .accordion:first-child {
  border-top: 1px dotted #ccc;
}
.accordion-wrap .accordion .accordion-toggle {
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: calc(1em + 10px);
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  overflow: hidden;
}
.accordion-wrap .accordion .accordion-toggle:before {
  float: right;
  color: inherit;
  content: "\e817";
  font-family: "icons";
  font-size: 1em;
  font-weight: normal !important;
  height: inherit;
  width: 1em;
  margin-right: -1em;
  text-align: right;
}
.accordion-wrap .accordion .accordion-toggle:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.accordion-wrap .accordion.is-open > .accordion-content {
  padding-top: 5px;
  opacity: 1;
  transform: scaleY(1);
}
.accordion-wrap .accordion.is-open > .accordion-toggle:before {
  content: "\e816";
}
.accordion-wrap .accordion .accordion-content {
  padding: 0 10px;
  max-height: 0;
  opacity: 0;
  transform-origin: top;
  transition: max-height 0.35s cubic-bezier(0.075, 0.82, 0.165, 1), opacity 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow: hidden;
}
.accordion-wrap .accordion.overflow {
  overflow: visible;
  z-index: 1;
  position: relative;
}
.accordion-wrap .accordion.overflow .accordion-content {
  overflow: visible;
}

/* ----------------------------------------------------------------------------
  Bootstrap Overrides
----------------------------------------------------------------------------- */
b,
strong {
  font-weight: 600;
}

.modal-header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.modal-header h1,
.modal-header h2,
.modal-header h3,
.modal-header h4,
.modal-header h5 {
  margin-top: 0;
  font-weight: 400;
}

input[type=number] {
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder {
  color: #e0e0e0 !important;
}

.clearfix {
  clear: both;
}

.custom-control.is-invalid .custom-control-input ~ .custom-control-label, .custom-control:invalid .custom-control-input ~ .custom-control-label {
  color: #fb4f16;
}
.custom-control.is-invalid .custom-control-input ~ .custom-control-label:before, .custom-control:invalid .custom-control-input ~ .custom-control-label:before {
  border-color: #fb4f16;
}
.custom-control.is-invalid .custom-control-input:focus ~ .custom-control-label:before, .custom-control:invalid .custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-control.custom-checkbox .custom-control-label:before, .custom-control.custom-checkbox .custom-control-label:after {
  left: -1.75rem;
  width: 1.2rem;
  height: 1.2rem;
}
.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #316ce8;
  background-color: #316ce8;
}
.custom-control.custom-radio {
  padding-left: 1.75rem;
}
.custom-control.custom-radio .custom-control-label:before, .custom-control.custom-radio .custom-control-label:after {
  left: -1.75rem;
  width: 1.2rem;
  height: 1.2rem;
}
.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #316ce8;
  background-color: #316ce8;
}

.form-control.is-invalid {
  border-color: #fb4f16;
}
.form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  background-image: none;
}

.custom-select.is-invalid {
  border-color: #fb4f16;
}
.custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #80bdff;
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
}

.table {
  word-wrap: normal;
  word-break: normal;
}
.table.align-middle th,
.table.align-middle td {
  vertical-align: middle;
}
.table.table-sm td,
.table.table-sm th {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.list-group-item {
  margin-top: 0;
  margin-bottom: 0;
}

.form-check.custom-checkbox {
  padding-left: 1.8rem;
}
.form-check.custom-checkbox .form-check-input {
  width: 1.3em;
  height: 1.3em;
  margin-top: 0.15em;
  margin-left: -1.8em;
  border-color: #adb5bd;
}
.form-check.custom-checkbox .form-check-input:checked {
  border-color: #0d6efd;
}
.form-check.custom-checkbox .form-check-input:focus {
  box-shadow: none;
}

/* ----------------------------------------------------------------------------
  ASC Default stuff
----------------------------------------------------------------------------- */
.price {
  display: inline-block;
  padding: 0;
  width: auto;
  height: auto;
  color: #555;
  text-align: center;
  font: bold 0 "Trebuchet MS", "Droid Serif", Arial, sans-serif;
  overflow: visible;
  vertical-align: middle;
}

.price.static {
  color: #555;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
}

.price.shown {
  color: #555;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
  transition: color ease-in-out 0.1s, text-shadow ease-in-out 0.1s;
}

.price.ribbon {
  margin-top: -6px;
  margin-bottom: 0;
  padding: 28px 0 0 0;
  width: 140px;
  height: 93px;
  color: #fff;
  background: url(../images/price_bg.png) no-repeat 0 0;
}

.price.burst {
  padding: 60px 0 0 0;
  width: 155px;
  height: 155px;
  color: #fff;
  background: url(../images/price_bg_burst.png) no-repeat 50% 50%;
}

.price.circle {
  padding: 45px 0 0 0;
  background: #f63;
  border-radius: 100px;
  width: 130px;
  height: 130px;
  color: #fff;
  text-shadow: 0 0 25px #fff;
  color: transparent;
}

.price.ribbon.static,
.price.burst.static {
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

.price.ribbon.shown,
.price.burst.shown,
.price.circle.shown {
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
  transition: color ease-in-out 0.1s, text-shadow ease-in-out 0.1s;
}

.price span.dollar-sign,
.price span.cents,
.price span.cents-fees,
.price span.only {
  display: inline-block;
  margin: 5px 0 0 0;
  font-size: 26px;
  line-height: 26px;
  vertical-align: top;
}

.price span.only {
  margin-bottom: 5px;
  font-size: 26px;
  display: block;
}

.price span.dollars,
.price span.dollars-fees {
  display: inline-block;
  margin-left: -1px;
  font-size: 48px;
  line-height: 48px;
  letter-spacing: -1px;
  vertical-align: middle;
}

.price span.cents,
.price span.cents-fees {
  margin: 5px 0 0 2px;
  letter-spacing: -1px;
}

.price.shown span.reg-price {
  font-size: 14px;
  font-weight: 400;
  color: #777;
  text-decoration: line-through;
  padding-left: 3px;
  vertical-align: middle;
  text-shadow: none;
}

.price.ribbon span.reg-price {
  color: #eee;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

.price span.dollar-sign {
  filter: blur(10px);
}

.price.shown span.dollar-sign {
  filter: blur(0);
}

.price span.dollars:after,
.price span.dollars-fees:after,
.price span.cents:after,
.price span.cents-fees:after,
.price span.dollars:after,
.price span.cents:after {
  display: inline-block;
  content: "##";
  text-shadow: 0 0 20px currentcolor;
  filter: blur(10px);
}

.price.shown span.dollars:after,
.price.shown span.dollars-fees:after,
.price.shown span.cents:after,
.price.shown span.cents-fees:after,
.price.shown span.dollars:after,
.price.shown span.cents:after {
  display: none;
  filter: blur(0);
}

span.slashprice {
  font-size: 20px;
  line-height: 20px;
  color: #888;
  font-weight: 400;
  margin-left: 7px;
  margin-right: 7px;
  vertical-align: middle;
  display: inline-block;
  animation: fadein 2s;
  -moz-animation: fadein 2s;
  -webkit-animation: fadein 2s;
  -o-animation: fadein 2s;
  position: relative;
  opacity: 0;
}

span.slashprice:after {
  display: block;
  content: "";
  position: absolute;
  left: -7%;
  top: 47%;
  width: 110%;
  height: 1px;
  background-color: #888;
  -webkit-transform: rotate(-10deg);
  transform: rotate(-10deg);
}

span.slashprice.text-white::after {
  background-color: #fff;
}

span.slashprice {
  opacity: 1;
}

.price-plain {
  font-size: 28px;
  line-height: 28px;
  padding-bottom: 2px;
}

/* -- for dynamic LGAs -- */
.lga-item {
  display: none;
  opacity: 0;
}
.lga-item.lga-replaced {
  display: inline;
  opacity: 1;
}

.text-shadow-none {
  text-shadow: none !important;
}

.img-hover img {
  transition: opacity 0.2s ease-in-out;
}
.img-hover img:hover {
  opacity: 0.95;
}

/* -- Customize Bootstrap Utilities-- */
.w-auto {
  max-width: 100% !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

@media (min-width: 576px) {
  .w-sm-auto {
    width: 100% !important;
  }
  .w-sm-5 {
    width: 5% !important;
  }
  .w-sm-10 {
    width: 10% !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-90 {
    width: 90% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
}
@media (min-width: 768px) {
  .w-md-auto {
    width: 100% !important;
  }
  .w-md-5 {
    width: 5% !important;
  }
  .w-md-10 {
    width: 10% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-90 {
    width: 90% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
}
@media (min-width: 992px) {
  .w-lg-auto {
    width: 100% !important;
  }
  .w-lg-5 {
    width: 5% !important;
  }
  .w-lg-10 {
    width: 10% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-90 {
    width: 90% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
}
@media (min-width: 1200px) {
  .w-xl-auto {
    width: 100% !important;
  }
  .w-xl-5 {
    width: 5% !important;
  }
  .w-xl-10 {
    width: 10% !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-90 {
    width: 90% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
}
@media (min-width: 1400px) {
  .w-xxl-auto {
    width: 100% !important;
  }
  .w-xxl-5 {
    width: 5% !important;
  }
  .w-xxl-10 {
    width: 10% !important;
  }
  .w-xxl-20 {
    width: 20% !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-30 {
    width: 30% !important;
  }
  .w-xxl-40 {
    width: 40% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-60 {
    width: 60% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-80 {
    width: 80% !important;
  }
  .w-xxl-90 {
    width: 90% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.ls-10 {
  letter-spacing: 0.1rem;
}

.ls-15 {
  letter-spacing: 0.15rem;
}

.ls-20 {
  letter-spacing: 0.2rem;
}

.ls-25 {
  letter-spacing: 0.25rem;
}

.lh-1 {
  line-height: 1;
}

.lh-12 {
  line-height: 1.2;
}

.lh-14 {
  line-height: 1.4;
}

.lh-16 {
  line-height: 1.6;
}

.lh-18 {
  line-height: 1.8;
}

.lh-20 {
  line-height: 2;
}

.lh-25 {
  line-height: 2.5;
}

/* ----------------------------------------------------------------------------
  Navigation Menu
----------------------------------------------------------------------------- */
#nav-wrap {
  background-color: #004dc1;
}

#menutoggle {
  display: block;
  margin: 0;
  padding: 0 22px;
  width: auto;
  color: #fff;
  background-color: #004dc1;
  height: 45px;
  font-size: 18px;
  line-height: 46px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.35);
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 4px 4px 0 0;
  transition: background-color 0.2s linear 0s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
#menutoggle span {
  display: none;
}
#menutoggle::before {
  display: inline-block;
  margin-left: 0;
  width: 18px;
  height: 45px;
  line-height: 48px;
  content: "\e826";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: 21px;
  vertical-align: top;
}
#menutoggle.is-active:before {
  content: "\e844";
}
#menutoggle:hover, #menutoggle:active, #menutoggle.is-active {
  background-color: #005ce7;
  border-radius: 4px 4px 0 0;
}
#menutoggle.is-active + ul {
  margin-bottom: 20px;
  max-height: 4000px;
  opacity: 1;
  transition: max-height 0.3s ease-in-out, opacity 0.25s ease-in-out;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}

#nav {
  clear: both;
  margin: 0;
  width: 100%;
  height: 0;
  overflow: visible;
  position: relative;
  z-index: 1000;
  border-top: 1px solid #004dc1;
}
#nav ul {
  float: none;
  display: block;
  margin: 0;
  padding: 0;
  width: auto;
  opacity: 0;
  max-height: 0;
  background-color: #004dc1;
  overflow: hidden;
  transition: max-height 0.25s cubic-bezier(0, 0.7, 0, 1), opacity 0.2s ease-in-out;
  border-radius: 0 0 4px 4px;
  top: 1px;
  border: 0;
  position: relative;
}
#nav ul.submenu {
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 0;
  background-color: #1b76ff;
  opacity: 1;
  position: relative;
  top: 0;
  transition: max-height 0.15s linear 0s;
  border-radius: 0;
}
#nav ul.submenu a,
#nav ul.submenu .no-link {
  padding-left: 40px;
  background: none;
  color: #fff;
  text-shadow: none;
  transition: padding-left 0.25s cubic-bezier(0, 0.7, 0, 1), background-color 0.25s cubic-bezier(0, 0.7, 0, 1);
}
#nav ul.submenu a:before,
#nav ul.submenu .no-link:before {
  display: inline-block;
  margin-right: 5px;
  margin-left: -15px;
  width: 10px;
  height: inherit;
  content: "»";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
  position: relative;
  top: -2px;
}
#nav ul.submenu a:hover,
#nav ul.submenu .no-link:hover {
  padding-left: 45px;
  background-color: #0056d8;
}
#nav ul.submenu span.submenu.level-2 {
  background-color: #0056d8;
  display: block;
  position: absolute;
  width: 55px;
  height: 44px;
  right: 0;
  top: 0;
  border-left: 1px solid #0056d8;
}
#nav ul.submenu span.submenu.level-2.is-active, #nav ul.submenu span.submenu.level-2:hover {
  background-color: #005ce7;
}
#nav ul.submenu ul.submenu {
  background-color: #f4f8ff;
  border-radius: 0;
}
#nav ul.submenu ul.submenu li a {
  padding-left: 45px;
  transition: padding-left 0.25s cubic-bezier(0, 0.7, 0, 1), background-color 0.25s cubic-bezier(0, 0.7, 0, 1);
  color: #333;
  text-shadow: none;
}
#nav ul.submenu ul.submenu li a:hover, #nav ul.submenu ul.submenu li a.is-active {
  padding-left: 50px;
  background-color: #a8caff;
}
#nav ul.submenu.is-active {
  background-color: #005ce7;
  border-bottom: 1px dotted #287eff;
}
#nav li {
  display: block;
  margin: 0;
  padding: 0;
  height: auto;
  overflow: hidden;
  border: 0;
  border-top: 1px dotted #287eff;
  position: relative;
}
#nav li:first-child {
  border-top: 0;
}
#nav li.onmobile {
  display: block;
}
#nav li.language-select {
  padding: 12px 20px;
}
#nav a,
#nav .no-link,
#nav span.submenu {
  float: none;
  display: block;
  margin: 0;
  padding: 9px 20px;
  height: auto;
  color: #fff;
  line-height: 1.6em;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  text-align: left;
  text-decoration: none;
  font-size: 16px;
  font-weight: normal;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: background-color 0.17s linear 0s, padding-left 0.17s linear 0s;
}
#nav a.login:before, #nav a.cart:before,
#nav .no-link.login:before,
#nav .no-link.cart:before,
#nav span.submenu.login:before,
#nav span.submenu.cart:before {
  display: inline-block;
  margin-right: 5px;
  color: inherit;
  content: "\ea77";
  font-family: "Material Icons Round";
  font-weight: normal;
  font-size: 110%;
  vertical-align: top;
  width: 23px;
  text-align: left;
}
#nav a.cart:before,
#nav .no-link.cart:before,
#nav span.submenu.cart:before {
  content: "\e8cc";
  font-family: "Material Icons Outlined";
}
#nav span.submenu:after,
#nav a.submenu:after {
  float: right;
  margin-left: 10px;
  width: 15px;
  height: inherit;
  content: "\e817";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}
#nav span.submenu.is-active,
#nav a.submenu.is-active {
  border-bottom: 1px dotted #005ce7;
}
#nav span.submenu.is-active + ul.submenu,
#nav a.submenu.is-active + ul.submenu {
  max-height: 2000px;
  overflow-y: auto;
}
#nav span.submenu.is-active + ul.submenu li,
#nav a.submenu.is-active + ul.submenu li {
  border-top: 1px dotted #004dc1;
}
#nav span.submenu.level-2 + ul.submenu,
#nav a.submenu.level-2 + ul.submenu {
  background-color: #c1daff;
}
#nav span.submenu.level-2 + ul.submenu li,
#nav a.submenu.level-2 + ul.submenu li {
  border-top: 1px dotted #287eff;
}
#nav span.submenu.is-active:after,
#nav a.submenu.is-active:after {
  content: "\e816";
}
#nav a:hover,
#nav a.is-active,
#nav li span.submenu:hover,
#nav li a.submenu:hover,
#nav ul.submenu a.is-active {
  background-color: #005ce7;
}
#nav ul.submenu a.is-active {
  background-color: #0056d8;
}

@media (min-width: 36em) {
  /* Small devices (landscape phones, 576px and up) */
  /*@media (min-width: 48em) { /* Medium devices (tablets, 768px and up) */
  #menutoggle {
    padding: 0 15px 0 15px;
  }
  #menutoggle span {
    display: inline-block;
  }
  #menutoggle:before {
    margin-right: 10px;
  }
}
@media (min-width: 1200px) {
  /* Large devices (desktops, 992px and up) */
  #menutoggle {
    display: none;
  }
  #nav {
    height: 45px;
    background: #004dc1;
    position: relative;
    z-index: 99;
    border: 0;
    border-radius: 4px;
  }
  #nav ul {
    display: block !important;
    float: left;
    width: 100%;
    height: auto;
    opacity: 1;
    margin: 4px 0;
    position: relative;
    z-index: 10;
    top: 0;
    overflow: visible;
  }
  #nav ul.submenu {
    float: left;
    margin: 0;
    padding: 0;
    min-width: 350px;
    max-width: 350px;
    max-height: 0;
    position: absolute;
    opacity: 0;
    background-color: #1b76ff;
    top: 100%;
    z-index: 10;
    border-radius: 0 3px 3px 3px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    transition: max-height 0.2s linear 0s, padding 0.2s linear 0s, opacity 0.2s linear 0s;
    overflow: hidden;
    overflow-y: auto;
  }
  #nav ul.submenu.right {
    border-radius: 3px 0 3px 3px;
    right: 0;
  }
  #nav ul.submenu li {
    float: none;
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    height: auto;
  }
  #nav ul.submenu li:last-child a {
    border-bottom: 1px solid #1b76ff;
  }
  #nav ul.submenu a:before,
  #nav ul.submenu .no-link:before {
    top: 0;
  }
  #nav ul.submenu a,
  #nav ul.submenu .no-link,
  #nav ul.submenu span.submenu.level-2 {
    float: none;
    display: block;
    margin: 0;
    padding: 7px 15px 7px 28px;
    height: auto;
    text-transform: none;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    text-align: left;
    line-height: 1.5em;
    border-radius: 0;
    border: 0;
    border-bottom: 1px dotted #004dc1;
  }
  #nav ul.submenu span.submenu.level-2 {
    padding-left: 15px;
  }
  #nav ul.submenu span.submenu.level-2:hover {
    background-color: #005ce7;
  }
  #nav ul.submenu span.submenu.level-2.is-active {
    background-color: #0054d3;
  }
  #nav ul.submenu span.submenu.level-2.is-active + ul.submenu {
    max-height: 500px !important;
    overflow-y: auto;
  }
  #nav ul.submenu span.submenu.level-2:after {
    display: block;
    width: 100%;
  }
  #nav ul.submenu ul.submenu {
    float: none;
    display: block;
    position: relative;
    padding: 0 !important;
    box-shadow: none;
    max-height: 0 !important;
    overflow: hidden;
    background-color: #dbe9ff;
  }
  #nav ul.submenu ul.submenu a:before {
    top: 0px;
  }
  #nav ul.submenu ul.submenu li {
    overflow: hidden;
  }
  #nav ul.submenu ul.submenu li a {
    border-radius: 0;
    padding-left: 35px !important;
    transition: padding-left 0.25s cubic-bezier(0, 0.7, 0, 1);
    color: #333;
    border-bottom: none;
  }
  #nav ul.submenu ul.submenu li a:hover {
    padding-left: 40px !important;
  }
  #nav li {
    float: left;
    margin: 0 5px;
    padding: 0;
    height: 37px;
    width: auto;
    min-width: auto;
    position: relative;
    border: 0;
    overflow: visible;
  }
  #nav li:first-child {
    margin-left: 0;
    border-left: 0;
  }
  #nav li.last {
    border-right: 0;
  }
  #nav li.right {
    float: right;
    margin-right: 0;
    border-right: 0;
  }
  #nav li.onmobile {
    display: none;
  }
  #nav li:hover {
    z-index: 11;
  }
  #nav li.callout a,
  #nav li.callout span.submenu {
    background-color: #1b76ff;
  }
  #nav a,
  #nav .no-link,
  #nav span.submenu {
    float: left;
    margin: 0;
    padding: 0 10px;
    height: 37px;
    border: 0;
    color: #fff;
    font-weight: normal;
    font-size: 14px;
    line-height: 39px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 3px;
    transition: background-color 0.2s linear 0s;
    cursor: pointer;
  }
  #nav span.submenu:after,
  #nav a.submenu:after {
    display: none;
  }
  #nav a:hover,
  #nav a.is-active,
  #nav li:hover span.submenu,
  #nav li:hover a.submenu,
  #nav li:hover a.submenu,
  #nav ul.submenu a:hover,
  #nav span.submenu.is-active,
  #nav a.submenu.is-active {
    background-color: #1b76ff;
  }
  #nav li:hover a.submenu,
  #nav li:hover span.submenu,
  #nav li:hover a.submenu,
  #nav span.submenu.is-active.hover,
  #nav a.submenu.is-active.hover {
    border-radius: 3px 3px 0 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  #nav li:hover ul.submenu,
  #nav ul.submenu.is-active {
    left: 0;
    max-height: 435px;
    height: fit-content;
    opacity: 1;
  }
  #nav li:hover ul.submenu.right,
  #nav ul.submenu.is-active.right {
    left: auto;
    right: 0;
  }
  #nav ul.submenu li a.is-active,
  #nav ul.submenu li a:hover,
  #nav ul.submenu li .no-link:hover {
    padding-left: 33px;
    background-color: #005ce7;
  }
  #nav ul.submenu li a.is-active {
    padding-left: 28px;
  }
  #nav ul.submenu li a.is-active:hover {
    padding-left: 33px;
  }
}
@media (min-width: 1200px) {
  #test-nav {
    height: 45px;
    margin-top: -2px;
  }
  #test-nav li {
    margin: 0 0;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    flex-grow: 1;
    height: 45px;
    margin-top: -2px;
  }
  #test-nav li:nth-child(2) {
    border-left: 1px solid rgba(255, 255, 255, 0.3);
  }
  #test-nav .no-link,
  #test-nav a,
  #test-nav span.submenu {
    float: left;
    margin: 0;
    padding: 0 15px;
    width: 100%;
    height: 45px;
    line-height: 45px;
  }
  #test-nav a.submenu.is-active.hover,
  #test-nav li:hover a.submenu,
  #test-nav li:hover span.submenu,
  #test-nav span.submenu.is-active.hover {
    border-radius: 0;
  }
}
/* ----------------------------------------------------------------------------
	Buttons
----------------------------------------------------------------------------- */
@keyframes btnProcessing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cta-btn {
  display: inline-block;
  padding: 10px 0 8px 0;
  color: #fff;
  background-color: #555555;
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 220px;
  line-height: 1.35;
  font-size: 21px;
  font-weight: normal;
  font-family: "Fjalla One", Impact, Verdana;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  border-radius: 4px;
  cursor: pointer;
  vertical-align: middle;
  outline: 0;
  overflow: hidden;
  transition: background-color 0.1s ease-in-out 0.05s;
  border-bottom: 0;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.cta-btn:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #3c3c3c 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.3s, opacity 0.5s;
}
.cta-btn:active:after {
  transform: scale(0, 0);
  opacity: 0.2;
  transition: 0s;
}
.cta-btn[data-pid] {
  position: relative;
  transition: padding-left 0.3s, opacity 0.5s;
}
.cta-btn[data-pid].btn-processing:before {
  display: inline-block;
  margin-right: 7px;
  content: "";
  top: 50%;
  left: 0.7rem;
  margin-top: -10px;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  border-radius: 20px;
  border: 3px solid #ccc;
  border-top-color: black;
  border-left-color: black;
  opacity: 0.5;
  animation: btnProcessing 1s linear infinite;
}
.cta-btn.disabled {
  background: #ddd !important;
  pointer-events: none;
  color: #616161 !important;
}
.cta-btn.small.btn-processing:before {
  width: 17px;
  height: 17px;
}
.cta-btn.short {
  width: 150px;
}
.cta-btn.long {
  max-width: 275px;
}
.cta-btn.default-font {
  font-family: var(--font-family);
  font-weight: bold;
  text-transform: none;
}

.cta-btn.fancy {
  background: #555555;
  background-image: linear-gradient(dimgray, #3c3c3c);
}

.cta-btn:hover,
.cta-btn:active {
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #434343;
  border: 0;
}

.cta-btn.big {
  padding: 13px 0 10px 0;
}

.cta-btn.big.outline {
  padding: 12px 0 9px 0;
}

.cta-btn.small {
  padding: 9px 0 7px 0;
  font-size: 18px;
}

.cta-btn.small.fancy {
  line-height: 41px;
}

.cta-btn.small.outline {
  padding: 8px 0 6px 0;
}

.cta-btn.dark {
  color: #fff;
  background: #555555;
}

.cta-btn.orange {
  color: #fff;
  background: #ff6633;
}

.cta-btn.yellow {
  color: #333;
  background: #ffc107;
}

.cta-btn.green {
  color: #fff;
  background: #5cb865;
}

.cta-btn.red {
  color: #fff;
  background: #f44336;
}

.cta-btn.blue {
  color: #fff;
  background: #0099ff;
}

.cta-btn[class~=outline] {
  border: 1px solid #555555;
}

.cta-btn.outline {
  color: #555555;
  background: transparent;
  border-color: #555555;
}

.cta-btn.dark.outline {
  color: #555555;
  background: transparent;
  border-color: #555555;
}

.cta-btn.white.outline {
  color: #fff;
  background: transparent;
  border-color: #fff;
}

.cta-btn.orange.outline {
  color: #ff6633;
  background: transparent;
  border-color: #ff6633;
}

.cta-btn.green.outline {
  color: #5cb865;
  background: transparent;
  border-color: #5cb865;
}

.cta-btn.red.outline {
  color: #f44336;
  background: transparent;
  border-color: #f44336;
}

.cta-btn.yellow.outline {
  color: #ffc107;
  background: transparent;
  border-color: #ffc107;
}

.cta-btn.blue.outline {
  color: #0099ff;
  background: transparent;
  border-color: #0099ff;
}

.cta-btn.pill {
  border-radius: 100px;
}

.cta-btn.dark.fancy {
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  background: #555555;
  background-image: linear-gradient(dimgray, #3c3c3c);
}

.cta-btn.dark:hover,
.cta-btn.dark:active {
  background: #434343;
}

.cta-btn.outline:hover,
.cta-btn.outline:active,
.cta-btn.dark.outline:hover,
.cta-btn.dark.outline:active {
  background: rgba(85, 85, 85, 0.1);
}

.cta-btn.white.outline:hover,
.cta-btn.white.outline:active {
  background: rgba(255, 255, 255, 0.1);
}

.cta-btn.orange.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  background: #ff6633;
  background-image: linear-gradient(#ff855c, #ff531a);
}

.cta-btn.orange:hover,
.cta-btn.orange:active {
  background: #ff4b0f;
}

.cta-btn.orange.outline:hover,
.cta-btn.orange.outline:active {
  background: rgba(255, 102, 51, 0.1);
}

.cta-btn.green.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #5cb865;
  background-image: linear-gradient(#78c480, #4cae56);
  color: #fff;
}

.cta-btn.green:hover,
.cta-btn.green:active {
  background: #49a752;
}

.cta-btn.green.outline:hover,
.cta-btn.green.outline:active {
  background: rgba(92, 184, 101, 0.1);
}

.cta-btn.red.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #f44336;
  background-image: linear-gradient(#f6675d, #f32c1e);
}

.cta-btn.red:hover,
.cta-btn.red:active {
  background: #f22314;
}

.cta-btn.red.outline:hover,
.cta-btn.red.outline:active {
  background: rgba(244, 67, 54, 0.1);
}

.cta-btn.blue.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #0099ff;
  background-image: linear-gradient(#29a9ff, #008ae6);
}

.cta-btn.blue:hover,
.cta-btn.blue:active {
  background: #24a7ff;
}

.cta-btn.blue.outline:hover,
.cta-btn.blue.outline:active {
  background: rgba(0, 153, 255, 0.1);
}

.cta-btn.yellow.fancy {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background: #ffc107;
  background-image: linear-gradient(#ffce3a, #edb100);
}

.cta-btn.yellow:hover,
.cta-btn.yellow:active {
  background: #ffca2b;
}

.cta-btn.yellow.outline:hover,
.cta-btn.yellow.outline:active {
  background: rgba(255, 193, 7, 0.1);
}

/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
}

body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}

.wrap {
  padding: 30px 0;
}

.container-fluid.but-fixed {
  max-width: 1356px;
}

.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #999999;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
}
.footer p {
  margin: 0 0 5px 0;
}
.footer p.trustmark {
  display: none;
  margin: 0;
}
.footer a {
  color: #999999;
}
.footer .social a {
  border: 0;
}
.footer img {
  display: inline-block;
}

/* --------- asc styles --------- */
#header {
  background: rgba(255, 255, 255, 0.8);
  float: none;
}
#header a.logo {
  float: left;
  margin-top: 12px;
  margin-bottom: 12px;
  border: 0;
  width: 55%;
  max-width: 400px;
}
#header a.logo img {
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
  width: 100%;
  height: auto;
}
#header a.logo span.big,
#header a.logo span.small {
  display: inline;
  font-size: 1.5em;
  font-weight: 500;
  color: #555;
  line-height: 1em;
  vertical-align: middle;
}
#header a.logo span.big {
  font-weight: 600;
  font-family: var(--font-family);
}
#header a.logo span.small {
  display: none;
  margin-top: 0;
  font-size: 16px;
  font-family: var(--font-family);
  font-weight: normal;
}
#header #support {
  display: none;
  float: right;
  margin: 0 0 0 0;
  width: auto;
}
#header #support p {
  float: right;
  margin: 2px 12px 0 0;
}
#header #support p strong {
  font-size: 16px;
}
#header #support a.login {
  float: right;
  margin: 0 0 0 0;
  padding: 0 12px 2px 12px;
  font-weight: normal;
  color: #fff;
  background: #004dc1;
  text-decoration: none;
  border-radius: 0 0 4px 4px;
  border: 0;
}
#header #support a.login:before {
  display: inline-block;
  margin-right: 5px;
  width: inherit;
  height: inherit;
  content: "\e828";
  color: inherit;
  font-family: "icons";
  font-weight: normal;
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}
#header #support a.login:hover {
  background: #005ce7;
}
#header #support span.phone {
  display: none;
  padding-right: 8px;
  border-right: 1px dotted #333;
}
#header #support a.livechat {
  float: right;
  margin: 0 0 0 10px;
  font-weight: normal;
  color: #555;
}
#header #support a.livechat:before {
  display: inline-block;
  margin-right: 5px;
  width: inherit;
  height: inherit;
  content: "\e830";
  color: inherit;
  font-family: "icons";
  font-weight: normal;
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}
#header #support span.cart-link {
  float: right;
  padding-left: 10px;
  border-left: 1px dotted #333;
}
#header #support a.cart:before {
  display: inline-block;
  margin-right: 5px;
  width: inherit;
  height: inherit;
  content: "\e8cc";
  color: inherit;
  font-family: "Material Icons Outlined";
  font-weight: 400;
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}
#header #search {
  float: right;
  clear: right;
  margin: 12px 0;
}
#header #search input[type=search] {
  width: 130px;
  transition: all 0.5s cubic-bezier(0, 0.7, 0, 1);
}
#header #search input[type=search]:focus {
  width: 250px;
  background-color: #fff;
}
#header #search input[type=search]::placeholder {
  color: #999 !important;
}

input.addsearch {
  background: #f2f2f2 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cg fill='none' stroke='gray' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2'%3E%3Cpath d='M10 3a7 7 0 107 7 7 7 0 00-7-7zM21 21l-6-6' vector-effect='non-scaling-stroke'%3E%3C/path%3E%3C/g%3E%3C/svg%3E") no-repeat 9px center;
  padding-left: 35px;
}

#phones {
  padding: 2px 10px;
  background: #004dc1;
  color: #fff;
  font-weight: 600;
  font-size: 0.9em;
}

#nav form {
  padding: 9px 20px;
}
#nav form label {
  color: #fff;
}
#nav form input {
  margin-bottom: 5px;
  border: 0;
}

.bg-primary {
  background-color: #004dc1 !important;
}

.bg-orange {
  background: #f63;
}

.bg-light-blue {
  background: #0872b9 !important;
}

.bg-gray {
  background: #e5e5e5 !important;
}

.off-center {
  position: relative;
  top: -1px;
}

#banner-wrap {
  position: relative;
}
#banner-wrap h1,
#banner-wrap h2 {
  font-weight: 700 !important;
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.8), 0 0 7px rgb(0, 0, 0);
}
#banner-wrap h1,
#banner-wrap h2,
#banner-wrap h3,
#banner-wrap .h1,
#banner-wrap .h2,
#banner-wrap .h3 {
  color: #fff;
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.8), 0 0 7px rgb(0, 0, 0);
}
#banner-wrap .details {
  color: #fff;
  font-size: 1.2rem;
  text-shadow: 2px 1.5px 0 rgba(0, 0, 0, 0.8);
}
#banner-wrap .details ul {
  font-size: 1.1rem;
}
#banner-wrap .details ul li:before {
  color: #09f;
}
#banner-wrap .details ul a {
  color: inherit;
  border-bottom: dotted 1px;
}
#banner-wrap .details-contained {
  max-width: 610px;
}
#banner-wrap .dropdown-list {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}
#banner-wrap .dropdown-list.short {
  max-width: 220px;
}
#banner-wrap .filler {
  background-color: rgba(255, 255, 255, 0.85);
}
#banner-wrap .filler h1,
#banner-wrap .filler h2,
#banner-wrap .filler h3 {
  text-shadow: 0 0 0;
}
#banner-wrap .price.circle {
  background-color: transparent;
  padding-top: 0;
  width: auto;
  height: auto;
}
#banner-wrap .price.circle.offer .text {
  display: block;
  font-size: 14px;
  line-height: 1;
}
#banner-wrap .price.circle span.subtext,
#banner-wrap .price.circle span.slashprice {
  color: #efefef;
  margin-right: 0;
}
#banner-wrap .price.circle span.subtext {
  font-size: 14px;
}
#banner-wrap .price.circle span.slashprice {
  font-size: 24px;
  margin: 0px;
}
#banner-wrap .price.circle span.slashprice:after {
  background-color: #efefef;
}
#banner-wrap img.iacet {
  max-width: 150px;
  height: auto;
  position: absolute;
  bottom: 40px;
  right: 20px;
}
#banner-wrap .cta-btn,
#banner-wrap .cta-dropdown {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}
#banner-wrap .special-offer {
  background: #fff;
  width: max-content;
  color: #555;
  text-shadow: none;
  border-radius: 15px;
  border: 2px solid #f63;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.65);
  overflow: hidden;
}
#banner-wrap .transparent {
  background: none;
  border: none;
  margin: 0 auto;
}
#banner-wrap .action.action-btns .cta-btn {
  width: 220px;
}
#banner-wrap .action.action-btns .cta-btn.large {
  min-width: 300px;
}
#banner-wrap.faded-bg * {
  z-index: 1;
}
#banner-wrap.faded-bg:before {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.6;
  background-color: #000;
}
#banner-wrap.abc {
  background: #000 url(../images/banners/abc-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.aerial-scissor-lift {
  background: #000 url(../images/banners/aerial-scissor-lift-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.brand-awareness, #banner-wrap.promo-codes {
  background: #000 url(../images/banners/brand-awareness-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.business {
  background: #000 url(../images/banners/business-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.business.lp {
  background: #000 url(../images/banners/business-bg-lp-sm.jpg) no-repeat 50% 0/cover;
}
#banner-wrap.calosha {
  background: #000 url(../images/banners/calosha-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.calosha-10-con {
  background: #000 url(../images/banners/calosha-10-con-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.calosha-30-con {
  background: #000 url(../images/banners/calosha-30-con-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.calosha-10-gen {
  background: #000 url(../images/banners/calosha-10-gen-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.calosha-30-gen {
  background: #000 url(../images/banners/calosha-30-gen-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.concrete-masonry {
  background: #000 url(../images/banners/concrete-and-masonry-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.construction-social {
  background: #000 url(../images/banners/oec2-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.construction-industry-lp {
  background: #000 url(../images/banners/construction-industry-lp-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.current-workers {
  background: #000 url(../images/banners/current-workers-bg-sm.jpg) no-repeat 50% 10%/cover;
}
#banner-wrap.dot {
  background: #000 url(../images/banners/dot-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.drug-alcohol {
  background: #000 url(../images/banners/da-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.electrocution-prevention {
  background: #000 url(../images/banners/electrocution-prevention-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.excavation {
  background: #000 url(../images/banners/excavations-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.fall-prevention {
  background: #000 url(../images/banners/fall-prevention-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.fire-protection {
  background: #000 url(../images/banners/fire-prevention-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.flagger {
  background: #000 url(../images/banners/flagger-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.forklift {
  background: #000 url(../images/banners/forklift-bg-sm.jpg) no-repeat 50% 10%/cover;
}
#banner-wrap.forklift-2 {
  background: #000 url(../images/banners/forklift-2-bg-sm.jpg) no-repeat 50% 10%/cover;
}
#banner-wrap.general-social {
  background: #000 url(../images/banners/oec3-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.hazwoper {
  background: #000 url(../images/banners/hazwoper-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.hazmat {
  background: #000 url(../images/banners/hazmat-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.home {
  background: #000 url(../images/banners/banner-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.lead-awareness {
  background: #000 url(../images/banners/lead-awareness-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.manager-refresher {
  background: #000 url(../images/banners/manager-refresher-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.msha {
  background: #000 url(../images/banners/msha-bg.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.new-entrants {
  background: #000 url(../images/banners/new-entrants-bg-sm.jpg) no-repeat 50% 10%/cover;
}
#banner-wrap.ny-construction {
  background: #000 url(../images/banners/construction-industry-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.ny-courses {
  background: #000 url(../images/banners/ny-main-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.ny-general {
  background: #000 url(../images/banners/general-industry-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.oeca {
  background: #000 url(../images/banners/oeca-bg.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.about-us {
  background: #000 url(../images/banners/oeca-bg.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.offer1 {
  background-image: linear-gradient(to right, rgba(193, 101, 5, 0.45) 0, rgba(0, 0, 0, 0) 100%), url(../images/banners/offer1-bg-sm.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
#banner-wrap.offer2 {
  background-image: linear-gradient(to right, rgba(193, 101, 5, 0.45) 0, rgba(0, 0, 0, 0) 100%), url(../images/banners/offer2-bg-sm.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
#banner-wrap.offer3 {
  background-image: linear-gradient(to right, rgba(4, 142, 195, 0.53) 0, rgba(0, 0, 0, 0) 100%), url(../images/banners/offer3-bg-sm.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
#banner-wrap.offer4 {
  background-image: linear-gradient(to right, rgba(195, 101, 4, 0.48) 0, rgba(0, 0, 0, 0) 100%), url(../images/banners/offer4-bg-sm.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
#banner-wrap.pretask-safety-meeting {
  background: #000 url(../images/banners/pretask-safety-meeting-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.scaffolds-suspended {
  background: #000 url(../images/banners/scaffolds-suspended-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.site-safety-plan {
  background: #000 url(../images/banners/site-safety-plan-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.spanish-ten-construction-hazrec {
  background: #000 url(../images/banners/10-spanish-construction-hazrec-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.spanish-ten-construction-outreach {
  background: #000 url(../images/banners/10-spanish-construction-training-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.spanish-ten-genindustry-hazrec {
  background: #000 url(../images/banners/10-spanish-genindustry-hazrec-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.spanish-ten-genindustry-outreach {
  background: #000 url(../images/banners/10-spanish-genindustry-training-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.spanish-thirty-construction-hazrec {
  background: #000 url(../images/banners/30-spanish-construction-hazrec-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.spanish-thirty-construction-outreach {
  background: #000 url(../images/banners/30-spanish-construction-training-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.spanish-thirty-genindustry-hazrec {
  background: #000 url(../images/banners/30-spanish-genindustry-hazrec-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.spanish-thirty-genindustry-outreach {
  background: #000 url(../images/banners/30-spanish-genindustry-training-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.sst {
  background: #000 url(../images/banners/sst-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.sst-card {
  background: #000 url(../images/banners/sst-card-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.sst-law {
  background: #000 url(../images/banners/sst-info-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.stairways-ladders {
  background: #000 url(../images/banners/stairways-and-ladders-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.supervisors {
  background: #000 url(../images/banners/supervisors-bg-sm.jpg) no-repeat 50% 10%/cover;
}
#banner-wrap.supported-scaffold {
  background: #000 url(../images/banners/supported-scaffold-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.ten {
  background: #000 url(../images/banners/hour-10-bg-sm.jpg) no-repeat 50% 25%/cover;
}
#banner-wrap.ten-construction-international {
  background: #000 url(../images/banners/10-hour-construction-international-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.ten-construction-short {
  background: #000 url(../images/banners/construction-10-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.ten-general-short {
  background: #000 url(../images/banners/general-industry-10-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.ten-international {
  background: #000 url(../images/banners/10-hour-international-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.thirty {
  background: #000 url(../images/banners/hour-30-bg-sm.jpg) no-repeat 50% 25%/cover;
}
#banner-wrap.thirty-construction-short {
  background: #000 url(../images/banners/construction-30-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.thirty-general-short {
  background: #000 url(../images/banners/general-industry-30-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.toolbox-talks {
  background: #000 url(../images/banners/toolbox-talks-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.tools-power {
  background: #000 url(../images/banners/tools-power-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.welding-cutting {
  background: #000 url(../images/banners/welding-cutting-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.workers {
  background: #000 url(../images/banners/workers-bg-sm.jpg) no-repeat 50% 10%/cover;
}
#banner-wrap.train-to-work {
  background: #000 url(../images/banners/train-to-work-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.pallet-jack {
  background: #000 url(../images/banners/pallet-jack-bg-sm.jpg) no-repeat 50% 90%/cover;
}
#banner-wrap.telehandler {
  background: #000 url(../images/banners/telehandler-bg-sm.jpg) no-repeat 50% 50%/cover;
}

a.img-hover img:hover {
  opacity: 0.8;
}

.dropdown-list {
  width: 100%;
  max-width: 300px;
}
.dropdown-list span.dropdown.cta-dropdown {
  padding: 16px;
  padding-bottom: 14px;
  color: #fff;
  font-weight: 400;
  background: #ff6431;
  border: 0;
  width: 100%;
  height: auto;
  line-height: 1;
  font-size: 22px;
  text-align: left;
  font-family: "Fjalla One", "Open Sans", "Trebuchet MS", Verdana, Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}
.dropdown-list span.dropdown.cta-dropdown.small {
  padding: 9px 15px 7px;
  font-size: 18px;
  line-height: 1.35;
}
.dropdown-list span.dropdown.cta-dropdown.small + .drop {
  top: 40px;
}
.dropdown-list span.dropdown.cta-dropdown + .drop {
  top: 50px;
}
.dropdown-list span.dropdown.rounded-pill {
  background: #fff;
  border-width: 2px;
  border-color: #0872b9;
  color: #0872b9;
  transition: 0.3s;
}
.dropdown-list span.dropdown.rounded-pill:hover {
  background: #0872b9;
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.dropdown-list span.dropdown.rounded-pill + .drop {
  width: 266px;
  left: 17px;
}

#welcome-banner {
  display: none;
  padding: 8px 15px;
  font-weight: 600;
  color: #555;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  font-size: 1.1em;
}
#welcome-banner p {
  margin-bottom: 0;
  color: #fc4a1a;
}

.inner-action {
  position: relative;
  min-width: 180px;
  z-index: 1;
}
.inner-action .cta-btn {
  margin-top: -35px;
}
.inner-action .iacet img {
  max-width: 150px;
}

#courses-wrap {
  background-color: #f5f6f7;
}
#courses-wrap .card.course-display {
  border: 0;
  background: #fff url(../images/section_bg.jpg) top right no-repeat;
  transition: box-shadow 0.3s ease 0s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}
#courses-wrap .card.course-display:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}
#courses-wrap .card.course-display .cta-btn {
  min-width: 220px;
}

#icons-wrap .icon-item {
  border-bottom: 2px dotted #ddd;
}
#icons-wrap .icon-item:last-child {
  border-bottom: 0;
}

.card.course-display {
  transition: box-shadow 0.3s ease 0s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}
.card.course-display:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}
.card.reviews {
  color: #fff;
}
.card.reviews a {
  color: #fff;
}
.card.reviews p.review {
  font-size: 1.2em;
  font-style: italic;
}
.card.reviews img.award {
  max-width: 300px;
  width: 100%;
  height: auto;
}

#callout-wrap p a {
  color: #b4e0f6;
}

#video-wrap {
  background: #fafafa;
}
#video-wrap a {
  display: inline-block;
  color: #666;
  border: 0;
  position: relative;
}
#video-wrap a span {
  display: inline-block;
  font-family: "Fjalla One", "Trebuchet MS", Tahoma, Verdana, Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  line-height: 1.3;
  text-align: left;
  vertical-align: middle;
}
#video-wrap a:before {
  display: inline-block;
  margin-right: 10px;
  width: 52px;
  height: 40px;
  content: "";
  background: url(../images/video-icon.png) no-repeat 0 0/cover;
  vertical-align: middle;
}

blockquote {
  font-size: 1.35rem;
  line-height: 1.2;
  font-weight: 400;
  color: inherit;
}
blockquote.headline {
  font-size: 1.75rem;
}
blockquote p {
  font-family: Georgia, "Open Sans", "Trebuchet MS", Verdana, Arial, Helvetica, sans-serif;
  font-style: italic;
  line-height: 1.5;
}
blockquote p:before, blockquote p:after {
  margin-right: 5px;
  display: inline-block;
  content: "“";
  color: inherit;
  opacity: 0.5;
  font-family: serif;
  font-size: 32px;
  font-weight: 700;
  vertical-align: middle;
  font-style: normal;
  line-height: initial;
  height: 20px;
  margin-top: -13px;
}
blockquote p:after {
  margin-left: 5px;
  content: "”";
}
blockquote a {
  color: inherit;
}

#reviews-wrap {
  background: #fafafa;
}
#reviews-wrap blockquote {
  color: #777;
}
#reviews-wrap p.more-reviews {
  font-family: "Fjalla One", "Open Sans", "Trebuchet MS", Verdana, Arial, Helvetica, sans-serif;
}

.review blockquote {
  font-family: "Georgia", "Verdana", "Arial", "Helvetica", "sans-serif";
  font-size: 1em;
}

.about-card .about-item {
  border-bottom: 1px dotted #aaa;
}
.about-card .col-lg-6:last-child .about-item {
  border-bottom: 0;
}

aside .dropdown-list {
  max-width: 100%;
}

#course-list .col-check {
  width: auto;
  padding-left: 15px;
  padding-right: 15px;
}
#course-list .sub-header {
  background-color: #ddd;
}
#course-list .sub-header .col-check {
  width: 150px;
}
#course-list .col-2 {
  min-width: 35px;
}
#course-list .regular-price.slashprice {
  font-size: 1em;
}

label.custom-checkbox {
  margin: 0;
  display: inline-block;
  height: 32px;
  width: 32px;
  cursor: pointer;
  position: relative;
  vertical-align: middle;
}
label.custom-checkbox.small {
  height: 25px;
  width: 25px;
}
label.custom-checkbox.small input[type=checkbox] ~ span:before {
  width: 25px;
  height: 25px;
  top: -2px;
}
label.custom-checkbox.small input[type=checkbox]:checked ~ span:before {
  background-size: 15px 15px;
}
label.custom-checkbox input[type=checkbox] {
  display: inline-block;
  width: 1px;
  height: 1px;
  position: absolute;
  opacity: 0;
  z-index: -1;
  top: 3px;
  left: 3px;
}
label.custom-checkbox input[type=checkbox] ~ span:before {
  display: block;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0;
  top: 1px;
  border: 2px solid #ccc;
  content: "";
  border-radius: 3px;
  background-color: #fff;
  background-size: 5px 5px;
  background-position: center center;
  transition: all 0.2s ease 0s;
}
label.custom-checkbox input[type=checkbox]:checked ~ span:before {
  border: 2px solid #2b99ff;
  background-position: center center;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjAuMiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMkI5OUZGO30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTE5LjEsMy4zbC05LjcsMTBMNC45LDkuMmwtMy43LDMuN2w4LjEsNy44TDIyLjgsN0wxOS4xLDMuM3oiLz4KPC9zdmc+);
  transition: all 0.2s ease 0s;
}

.accordion-wrap .accordion-toggle.icon {
  position: relative;
}
.accordion-wrap .accordion-toggle.icon:before {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 0;
}

.description-accordion .accordion-toggle {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-right: calc(1em + 10px);
  cursor: pointer;
  overflow: hidden;
}
.description-accordion .accordion-toggle:before {
  float: right;
  color: inherit;
  content: "\e817";
  font-family: "icons";
  font-size: 1.1em;
  font-weight: normal !important;
  height: inherit;
  width: 1em;
  margin-right: -1em;
  text-align: right;
}
.description-accordion .accordion-toggle:hover {
  color: #316ce8;
}
.description-accordion.is-open > .accordion-content {
  height: auto;
  opacity: 1;
  transform: scaleY(1);
  transition: transform 0.1s ease-in-out, height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.description-accordion.is-open > .accordion-toggle:before {
  content: "\e816";
}
.description-accordion .accordion-content {
  padding: 0;
  height: 0;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.2s ease-in-out, height 0.3s cubic-bezier(0, 0.6, 0, 1), opacity 0.3s cubic-bezier(0, 0.6, 0, 1);
  overflow: hidden;
}

#offer-modal {
  padding-right: 0 !important;
}

#offer-modal .modal-content {
  background: #ffc48f;
  color: #000;
}

#offer-modal .modal-body .btn-close {
  background-color: transparent;
}

#offer-modal p {
  font-size: 1.3em;
  line-height: 1.6em;
}

#offer-modal p span {
  display: block;
  margin-top: 5px;
  font-size: 1.5em;
  color: #e10000;
}

.text-highlight {
  color: #cfefff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.8);
}

.bg-forklift {
  background: #000 url(../images/banners/forklift-bg-sm.jpg) no-repeat 50% 10%/cover;
}

.bg-train-to-work {
  background: #000 url(../images/banners/banner-bg-sm.jpg) no-repeat 50% 55%/cover;
}

.bg-osha-promo {
  background: #000 url(../images/banners/sst-card-bg-sm.jpg) no-repeat 50% 10%/cover;
}

#state-courses .card-footer {
  padding: 0;
  background-color: transparent;
  border-top: 0;
}
#state-courses .border-top-highlight {
  border-top: 3px solid #004dc1;
}

.state-box:hover div {
  transition: 0.3s;
  background-color: #f2f2f2;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

#bulk-quantity.table {
  --bs-table-bg: transparent;
}
#bulk-quantity.table tr:last-child td {
  border-bottom: 0;
}

.upsell {
  background: #eef8ff;
}
.upsell .description strong {
  cursor: pointer;
}

#select-course:focus {
  width: auto\9 ;
}
#select-course option {
  max-width: 200px;
}

#mobile-cart {
  right: 75px;
  bottom: 5px;
  font-size: 22px;
}
#mobile-cart .cart:before {
  display: inline-block;
  content: "\e8cc";
  font-family: "Material Icons Outlined";
  vertical-align: middle;
}
#mobile-cart .cart-text {
  font-size: 0.8em;
}

.separator-bottom {
  position: relative;
  padding-bottom: 1rem;
}
.separator-bottom::after {
  content: "";
  position: absolute;
  width: 150px;
  height: 2px;
  background-color: #fff;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

/* ABC OSHA PAGE */
#abc-icon-wrap .icon h2:before {
  display: block;
  margin: 0 auto 10px auto;
  width: 120px;
  height: 120px;
  content: "";
  background: transparent url("../images/icons/feature_icons.png") no-repeat 0 0;
  background-size: cover;
}
#abc-icon-wrap .icon h2.feature-2:before {
  background-position: -120px 0;
}
#abc-icon-wrap .icon h2.feature-3:before {
  background-position: -240px 0;
}

#pill-tab .nav-item .nav-link {
  background: #fff;
  border-width: 2px;
  border-color: #0872b9;
  color: #0872b9;
  transition: 0.3s;
  width: 220px;
}
#pill-tab .nav-item .nav-link:hover, #pill-tab .nav-item .nav-link.active {
  background: #0872b9;
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.overlay-wrapper {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.overlay-wrapper .overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.3s;
}
.overlay-wrapper .banner {
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
}
.overlay-wrapper:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
}
.overlay-wrapper:hover .overlay {
  background-color: rgba(0, 0, 0, 0.6);
}

.wrap.overlay {
  position: relative;
}
.wrap.overlay:before {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.85;
  background-color: #000;
}
.wrap.overlay.construction-challenges {
  background: #000 url(../images/banners/construction-challenges-sm.jpg) no-repeat 50% 50%/cover;
}
.wrap.overlay.blue:before {
  opacity: 0.9;
  background-color: #1276b9;
}
.wrap.overlay.promise {
  background: #1276b9 url(../images/banners/business-promise-sm.jpg) no-repeat 50% 50%/cover;
}

.variant-nav {
  display: none !important;
}

.hover-fade:hover {
  opacity: 0.85;
}

.mw-mc {
  max-width: max-content;
}

.post-inline-img {
  max-width: 100%;
}

@media (min-width: 576px) {
  .post-inline-img.aligned {
    max-width: 35%;
  }
}
.highlight-burst {
  width: 155px;
  height: 155px;
  font-size: 1.1rem;
  line-height: 1.4;
  font-weight: 600;
  background: url(../images/price_bg_burst_empty.png) no-repeat 50% 50%;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
@media (min-width: 576px) {
  #mobile-cart {
    right: 130px;
  }
  #video-wrap a span {
    font-size: 1.5rem;
  }
  #offer-modal .modal-dialog {
    max-width: 750px;
  }
  .btn-width {
    width: 220px;
  }
  #icons-wrap .icon-item {
    border-bottom: 0;
    border-right: 2px dotted #ddd;
  }
  #icons-wrap .icon-item:nth-child(2n) {
    border-right: 0;
  }
  .w-sm-auto {
    width: auto !important;
  }
}
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo img {
    display: block;
    float: left;
  }
  #header a.logo span.big {
    display: inline-block;
    font-size: 1.9em;
  }
  #header a.logo span.small {
    display: inline-block;
  }
  #banner-wrap .price.circle {
    padding: 40px 0 0 0;
    background: #f63;
    border-radius: 100px;
    width: 130px;
    height: 130px;
  }
  #banner-wrap .price.circle .regular-price.slashprice {
    margin-top: 3px;
  }
  #banner-wrap .price.circle.bigger {
    padding-top: 55px;
    width: 160px;
    height: 160px;
  }
  #banner-wrap .price.circle.offer {
    padding-top: 30px;
  }
  #banner-wrap .price.circle.bigger.offer {
    padding-top: 50px;
  }
  #banner-wrap .price.circle.bigger.offer:has(.slashprice:not(.display-none)) {
    padding-top: 35px;
  }
  #banner-wrap .price.circle span.sale-msg,
  #banner-wrap .price.circle div.sale-msg {
    margin-top: -26px;
  }
  #course-list .col-check {
    width: 120px;
  }
  #course-list .sub-header .col-check {
    width: 150px;
  }
  #pill-tab {
    max-width: 700px;
  }
  .wrap.overlay.construction-challenges {
    background: #000 url(../images/banners/construction-challenges.jpg) no-repeat 50% 50%/cover;
  }
  .wrap.overlay.promise {
    background: #1276b9 url(../images/banners/business-promise.jpg) no-repeat 50% 20%/cover;
  }
}
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  #header a.logo {
    width: 50%;
  }
  #header a.logo span.small {
    font-size: 18px;
  }
  #header-wrap {
    border-top: 3px solid #004dc1;
  }
  #header-wrap #header #support {
    display: block;
  }
  #header-wrap #header #support span.phone {
    display: inline-block;
  }
  #mobile-cart {
    display: none;
  }
}
@media (min-width: 992px) {
  #header a.logo {
    width: 50%;
  }
  #header a.logo span.small {
    font-size: 18px;
  }
  #header #support {
    display: block;
  }
  #header #support span.phone {
    display: inline-block;
  }
  #banner-wrap .details {
    padding-left: 40px;
  }
  #banner-wrap .transparent {
    margin: 0;
  }
  #banner-wrap .action {
    padding-left: 40px;
  }
  #banner-wrap .circle.offer {
    background: #f63;
    border-radius: 100px;
    width: 130px;
    height: 130px;
    padding-top: 30px;
    text-align: center;
    text-shadow: none;
  }
  #banner-wrap .circle.offer .special-offer-text {
    display: block;
    font-size: 14px;
    line-height: 1;
  }
  #banner-wrap .circle.offer .offer {
    display: block;
    margin: 5px 0;
    font-size: 25px;
    font-weight: 700;
    line-height: 1;
  }
  #banner-wrap .circle.offer .disclaimer {
    display: block;
    padding: 0 15px;
    font-size: 13px;
    line-height: 1;
  }
  #banner-wrap.abc {
    background: #000 url(../images/banners/abc-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.aerial-scissor-lift {
    background: #000 url(../images/banners/aerial-scissor-lift-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.brand-awareness, #banner-wrap.promo-codes {
    background: #000 url(../images/banners/brand-awareness-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.business {
    background: #000 url(../images/banners/business-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.business.lp {
    background: #000 url(../images/banners/business-bg-lp.jpg) no-repeat 50% 0/cover;
  }
  #banner-wrap.calosha {
    background: #000 url(../images/banners/calosha-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.calosha-10-con {
    background: #000 url(../images/banners/calosha-10-con-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.calosha-30-con {
    background: #000 url(../images/banners/calosha-30-con-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.calosha-10-gen {
    background: #000 url(../images/banners/calosha-10-gen-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.calosha-30-gen {
    background: #000 url(../images/banners/calosha-30-gen-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.construction-social {
    background: #000 url(../images/banners/oec2-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.construction-industry-lp {
    background: #000 url(../images/banners/construction-industry-lp.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.concrete-masonry {
    background: #000 url(../images/banners/concrete-and-masonry-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.current-workers {
    background: #000 url(../images/banners/current-workers-bg.jpg) no-repeat 50% 20%/cover;
  }
  #banner-wrap.dot {
    background: #000 url(../images/banners/dot-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.drug-alcohol {
    background: #000 url(../images/banners/da-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.electrocution-prevention {
    background: #000 url(../images/banners/electrocution-prevention-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.excavation {
    background: #000 url(../images/banners/excavations-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.fall-prevention {
    background: #000 url(../images/banners/fall-prevention-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.fire-protection {
    background: #000 url(../images/banners/fire-prevention-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.flagger {
    background: #000 url(../images/banners/flagger-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.forklift {
    background: #000 url(../images/banners/forklift-bg.jpg) no-repeat 50% 30%/cover;
  }
  #banner-wrap.forklift-2 {
    background: #000 url(../images/banners/forklift-2-bg.jpg) no-repeat 50% 30%/cover;
  }
  #banner-wrap.general-social {
    background: #000 url(../images/banners/oec3-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.hazwoper {
    background: #000 url(../images/banners/hazwoper-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.hazmat {
    background: #000 url(../images/banners/hazmat-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.home {
    background: #000 url(../images/banners/banner-bg.jpg) no-repeat 25px 50%/cover;
  }
  #banner-wrap.lead-awareness {
    background: #000 url(../images/banners/lead-awareness-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.manager-refresher {
    background: #000 url(../images/banners/manager-refresher-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.new-entrants {
    background: #000 url(../images/banners/new-entrants-bg.jpg) no-repeat 50% 10%/cover;
  }
  #banner-wrap.ny-construction {
    background: #000 url(../images/banners/construction-industry-bg.jpg) no-repeat 80% 50%/cover;
  }
  #banner-wrap.ny-courses {
    background: #000 url(../images/banners/ny-main-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.ny-general {
    background: #000 url(../images/banners/general-industry-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.offer1 {
    background-image: linear-gradient(to right, rgba(193, 101, 5, 0.45) 0, rgba(0, 0, 0, 0) 100%), url(../images/banners/offer1-bg.jpg);
  }
  #banner-wrap.offer2 {
    background-image: linear-gradient(to right, rgba(193, 101, 5, 0.45) 0, rgba(0, 0, 0, 0) 100%), url(../images/banners/offer2-bg.jpg);
  }
  #banner-wrap.offer3 {
    background-image: linear-gradient(to right, rgba(4, 142, 195, 0.53) 0, rgba(0, 0, 0, 0) 100%), url(../images/banners/offer3-bg.jpg);
  }
  #banner-wrap.offer4 {
    background-image: linear-gradient(to right, rgba(195, 101, 4, 0.48) 0, rgba(0, 0, 0, 0) 100%), url(../images/banners/offer4-bg.jpg);
  }
  #banner-wrap.pretask-safety-meeting {
    background: #000 url(../images/banners/pretask-safety-meeting-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.scaffolds-suspended {
    background: #000 url(../images/banners/scaffolds-suspended-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.site-safety-plan {
    background: #000 url(../images/banners/site-safety-plan-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.spanish-ten-construction-hazrec {
    background: #000 url(../images/banners/10-spanish-construction-hazrec-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.spanish-ten-construction-outreach {
    background: #000 url(../images/banners/10-spanish-construction-training-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.spanish-ten-genindustry-hazrec {
    background: #000 url(../images/banners/10-spanish-genindustry-hazrec-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.spanish-ten-genindustry-outreach {
    background: #000 url(../images/banners/10-spanish-genindustry-training-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.spanish-thirty-construction-hazrec {
    background: #000 url(../images/banners/30-spanish-construction-hazrec-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.spanish-thirty-construction-outreach {
    background: #000 url(../images/banners/30-spanish-construction-training-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.spanish-thirty-genindustry-hazrec {
    background: #000 url(../images/banners/30-spanish-genindustry-hazrec-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.spanish-thirty-genindustry-outreach {
    background: #000 url(../images/banners/30-spanish-genindustry-training-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.sst {
    background: #000 url(../images/banners/sst-bg.jpg) no-repeat 0 0/cover;
  }
  #banner-wrap.sst-card {
    background: #000 url(../images/banners/sst-card-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.sst-law {
    background: #000 url(../images/banners/sst-info-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.stairways-ladders {
    background: #000 url(../images/banners/stairways-and-ladders-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.supervisors {
    background: #000 url(../images/banners/supervisors-bg.jpg) no-repeat 50% 10%/cover;
  }
  #banner-wrap.supported-scaffold {
    background: #000 url(../images/banners/supported-scaffold-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.ten {
    background: #000 url(../images/banners/hour-10-bg.jpg) no-repeat 90% 20%/cover;
  }
  #banner-wrap.ten-construction-short {
    background: #000 url(../images/banners/construction-10-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.ten-construction-international {
    background: #000 url(../images/banners/10-hour-construction-international-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.ten-general-short {
    background: #000 url(../images/banners/general-industry-10-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.ten-international {
    background: #000 url(../images/banners/10-hour-international-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.thirty {
    background: #000 url(../images/banners/hour-30-bg.jpg) no-repeat 50% 25%/cover;
  }
  #banner-wrap.thirty-construction-short {
    background: #000 url(../images/banners/construction-30-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.thirty-general-short {
    background: #000 url(../images/banners/general-industry-30-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.toolbox-talks {
    background: #000 url(../images/banners/toolbox-talks-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.tools-power {
    background: #000 url(../images/banners/tools-power-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.welding-cutting {
    background: #000 url(../images/banners/welding-cutting-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.workers {
    background: #000 url(../images/banners/workers-bg.jpg) no-repeat 50% 20%/cover;
  }
  #banner-wrap.train-to-work {
    background: #000 url(../images/banners/train-to-work-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.pallet-jack {
    background: #000 url(../images/banners/pallet-jack-bg.jpg) no-repeat 50% 95%/cover;
  }
  #banner-wrap.telehandler {
    background: #000 url(../images/banners/telehandler-bg.jpg) no-repeat 50% 50%/cover;
  }
  .inner-banner {
    border: 1px solid #ccc;
  }
  .inner-banner.certs {
    background: #fff url(../images/banners/training-certificate.jpg) no-repeat 50% 50%/cover;
  }
  .inner-banner.construction {
    background: #000 url(../images/banners/training-construction.jpg) no-repeat 50% 50%/cover;
  }
  .inner-banner.construction h1,
  .inner-banner.construction h2 {
    color: #fff;
  }
  .inner-banner.hazwoper {
    background: #000 url(../images/banners/training-hazwoper.jpg) no-repeat 50% 50%/cover;
  }
  .inner-banner.hazwoper h1,
  .inner-banner.hazwoper h2 {
    color: #fff;
  }
  .inner-banner.standards {
    background: #000 url(../images/banners/training-standards.jpg) no-repeat 50% 50%/cover;
  }
  .inner-banner.standards h1,
  .inner-banner.standards h2 {
    color: #fff;
  }
  .about-card .about-item {
    min-height: 120px;
  }
  .about-card .col-lg-6:nth-last-child(2) .about-item {
    border-bottom: 0;
  }
  .card.course-box .course-img {
    width: 60% !important;
  }
  .bg-forklift {
    background: #000 url(../images/banners/forklift-bg.jpg) no-repeat 50% 10%/cover;
  }
  .bg-train-to-work {
    background: #000 url(../images/banners/banner-bg-black.jpg) no-repeat 50% 70%/cover;
  }
  .bg-osha-promo {
    background: #000 url(../images/banners/sst-card-bg.jpg) no-repeat 50% 10%/cover;
  }
  #upsellModal {
    --bs-modal-width: 750px;
  }
  #icons-wrap .icon-item {
    border-right: 2px dotted #ddd !important;
  }
  #icons-wrap .icon-item:last-child {
    border-right: 0 !important;
  }
}
@media (min-width: 1200px) {
  #banner-wrap .details-contained {
    max-width: 680px;
  }
  #pill-tab {
    max-width: none;
  }
  #pill-tab .nav-item .nav-link {
    width: auto;
  }
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
  #banner-wrap.home {
    background-size: contain;
    background-position: 85% 50%;
  }
}